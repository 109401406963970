<template>
  <v-card
      :tile="textCard"
      elevation="1"
      :height="height"
      :style="textCard ? '' : 'border-left: 3px solid #5bb55f'"
      :disabled="disabled"
  >
    <MainModal
        :main="{ component: 'FlatCounterReading', title: 'Покази лічильника' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_counter_reading)"
        :modal="show_counter_reading_dialog"
        @updateItemModal="counterReadingUpdateItemModal"
    />
    <v-toolbar elevation="0" class="pa-0"
               :height="button && $vuetify.breakpoint.xs ? 96 : 56" :class="{'toolbar-wrap': button && $vuetify.breakpoint.xs }">
      <v-toolbar-title :style="!button ? '' : ''" class="toolbar-work-place-title">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="error" v-if="selectedCounter.id && !disabled">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :icon="!button" :depressed="button"
                 v-bind="attrs"
                 v-on="on"
                 class="grey lighten-4 mr-2" @click="deleteDialog">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Вилучити останій показник</span>
      </v-tooltip>
      <v-tooltip bottom color="success" v-if="selectedCounter.id && personal ? !disabled : !organization.cabinet_only && selectedCounter.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :icon="!button"
                 :outlined="button"
                 v-bind="attrs"
                 v-on="on"
                 @click="openCounterReadingCreateDialog" class="grey lighten-4 mr-1">
            <v-icon :class="button ? 'mr-2' : ''">mdi-plus</v-icon>
            <template v-if="button">
              Подати показник
            </template>
          </v-btn>
        </template>
        <span>Зареєструвати/Подати показник</span>
      </v-tooltip>

<!--      <MainModal-->
<!--          v-if="selectedCounter.id && personal-->
<!--          ? !disabled-->
<!--          : !organization.cabinet_only && selectedCounter.id"-->
<!--          :main="{ component: 'FlatCounterReading', title: 'Покази лічильника' }"-->
<!--          :button="{-->
<!--                    icon: !button,-->
<!--                    color: 'grey lighten-4',-->
<!--                    buttonIcon: 'mdi-plus',-->
<!--                    buttonName: button ? 'Подати показник' : '',-->
<!--                    buttonOutlined: button,-->
<!--                    buttonIconClass: button ? 'mr-2' : ''-->
<!--                  }"-->
<!--          :item="{-->
<!--                  flat_id: flat.id,-->
<!--                  flat_counter_id: selectedCounter ? selectedCounter.id : 0,-->
<!--                  service_id: selectedCounter ? selectedCounter.service_id : 0,-->
<!--                  serial_number: selectedCounter ? selectedCounter.serial_number : '',-->
<!--                  month: current_month,-->
<!--                  submission_date: current_date,-->
<!--                  zone: selectedCounter ? selectedCounter.zone : 0,-->
<!--                  prev_value_first: getPrevValue(1),-->
<!--                  prev_value_second: getPrevValue(2),-->
<!--                  prev_value_third: getPrevValue(3),-->
<!--                  personal: personal-->
<!--          }"-->
<!--          :tooltip="{active: true, title: 'Зареєструвати/Подати показник'}"-->
<!--      />-->
    </v-toolbar>
    <TableComponent
        :header="meterReadingHeaders"
        :items="meter_reading_data"
        :small="true"
        :sm_table="'sm-flat-reading-table'"
        :xs_table="'xs-flat-reading-table'"
        :full_height="true"
        :body_height="personal ? '260px' : '280px'"
        v-if="meter_reading_data.length"
    >
    </TableComponent>
  </v-card>
</template>

<script>
import { getAccountIcon, getAddCounterValueMethod } from "@/utils/icons"
import MainModal from "@/components/modal/MainModal";
import TableComponent from "@/components/tableComponent/TableComponent"
import {mapActions, mapGetters} from "vuex";
import {formatDate} from "@/filters";
import {QUESTION_SHOW} from "@/store/actions/question";
import {ALERT_SHOW} from "@/store/actions/alert";
import {REMOVE_FLAT_COUNTER_READING} from "@/store/actions/counter";
import {GET_ALL_WITH_BALANCE, GET_ARCHIVE} from "@/store/actions/flat_service";
import siteAdmin from "@/components/SiteAdmin.vue";

const modalDeleteId = "delete_meter_reading"

export default {
  name: "HWP_Pokaz",
  components: {
    MainModal,
    TableComponent
  },
  props: {
    nonClickable: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    textCard: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String,
      default: 'Архів показів'
    },
    person_hash: {
      type: String,
      default: ''
    },
    button: {
      type: Boolean,
      default: false
    },
    personal: {
      type: Boolean,
      default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      meterReadingHeaders: [
        {
          text: '',
          value: 'icon',
          width: 60,
          type: 'icon',
          iconFunc: getAccountIcon
        },
        {
          text: 'Місяць',
          value: 'month',
          type: 'date',
          format: 'MMMM YY р.',
          width: 110,
          style: {'font-weight: 500 !important': v=>v.month===`${this.current_month}`}
          },
        {text: 'Дата подання', value: 'submission_date', type: 'date',
          width: 110,
          style: {'font-weight: 500 !important': v=>v.month===`${this.current_month}`}
        },
        {
          text: 'Зона',
          value: 'zone_name',
          chip: { small: true, color: 'grey lighten-4', get_color: true },
          },
        {text: 'Попередній', value: 'prev_value_first'},
        {
          text: 'Поточний',
          value: 'current_value_first',
        },
        {
          text: 'Різниця',
          value: 'value_difference_first',
          style: {'font-weight: 500': v=>v.month===`${this.current_month}`}
        },
        {text: 'Спосіб подання', value: 'value_add_method', type: 'get', func: getAddCounterValueMethod},
      ],
      delete_watcher: null,
      delete_meter_id: 0,
      selected_counter_reading: {},
      show_counter_reading_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_WITH_BALANCE,
      getAccountArchive: GET_ARCHIVE
    }),
    getPrevValue(zone) {
      const zones = {
        1: ['start_meter_reading_first', 'current_meter_reading_first'],
        2: ['start_meter_reading_second', 'current_meter_reading_second'],
        3: ['start_meter_reading_third', 'current_meter_reading_third'],
      }
      if (!this.selectedCounter) return 0
      if (!zone) return 0

      const zone_obj = zones[zone]

      if (this.selectedCounter[zone_obj[1]]) return this.selectedCounter[zone_obj[1]]
      return this.selectedCounter[zone_obj[0]]
    },
    deleteDialog() {
      let filtered = this.meter_reading_data.filter(item => item.month === this.current_month)
      if (this.personal) {
        if (filtered.length) {
          if (filtered[0].value_add_method !== 'BY_USER') {
            filtered = []
          }
        }
      }
      if (!filtered.length) {
        this.$store.dispatch(ALERT_SHOW, { text: 'Не знайдено показів за поточний місяць', color: 'error lighten-1' })
        return
      }

      const last_item = filtered[0]

      this.delete_meter_id = Object.assign({}, last_item)

      const payload = {
        text: `Підтвердіть вилучення показника: ${last_item.current_value_first} від ${formatDate(last_item.submission_date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  const payload_local = {
                    id: this.delete_meter_id.id,
                    flat_id: this.delete_meter_id.flat_id,
                    flat_counter_id: this.delete_meter_id.flat_counter_id,
                    month: this.current_month,
                    calc: !this.personal,
                    cabinet: this.personal
                  }
                  this.$store.dispatch(REMOVE_FLAT_COUNTER_READING, payload_local)
                      .then(ok => {
                        if (ok) {
                          const person_hash = this.$route.params.person_hash
                          if (person_hash) {
                            this.fetchFlatServices(person_hash)
                            this.getAccountArchive(person_hash)
                          }
                          const cabinet_personal_hash = this.flat.person_hash || this.person_hash
                          if (this.personal && cabinet_personal_hash) {
                            this.fetchFlatServices(cabinet_personal_hash)
                            this.getAccountArchive(cabinet_personal_hash)
                          }
                        }
                      })
                }
                this.delete_meter_id = {}
              }
            }
          }
      )
    },
    openCounterReadingCreateDialog() {
      this.selected_counter_reading = {
        flat_id: this.flat.id,
        flat_counter_id: this.selectedCounter ? this.selectedCounter.id : 0,
        service_id: this.selectedCounter ? this.selectedCounter.service_id : 0,
        serial_number: this.selectedCounter ? this.selectedCounter.serial_number : '',
        month: this.current_month,
        submission_date: this.current_date,
        zone: this.selectedCounter ? this.selectedCounter.zone : 0,
        prev_value_first: this.getPrevValue(1),
        prev_value_second: this.getPrevValue(2),
        prev_value_third: this.getPrevValue(3),
        personal: this.personal,
        person_hash: this.flat.person_hash || this.person_hash
      }
      this.show_counter_reading_dialog = true
    },
    counterReadingUpdateItemModal() {
      this.show_counter_reading_dialog = false
      this.selected_counter_reading = {}
    },
  },
  computed: {
      siteAdmin() {
          return siteAdmin
      },
    ...mapGetters({
      flat_: 'getFlat',
      selectedCounter: 'getSelectedCounter',
      current_month: 'getCurrentMonth',
      meter_reading_data: 'getFlatMeterReading',
      modalAnswer: 'question_answer',
      flat_current: 'getCurrentUserFlat',
      organization: 'getCurrentOrganization'
    }),
    flat() {
      return this.personal ? this.flat_current : this.flat_
    },
    current_date() {
      const now = new Date()
      const start_month_now = new Date(now.getFullYear(), now.getMonth(), 1);
      const month = new Date(this.current_month)
      const end_of_month = new Date(month.getFullYear(), month.getMonth() + 1, 0)
      const prev_date = this.selectedCounter
          ? new Date(this.selectedCounter.value_date)
          : null

      if (start_month_now > month) {
        if (prev_date) {
          if (prev_date >= end_of_month) {
            return formatDate(prev_date, 'YYYY-MM-DD')
          } else {
            return formatDate(end_of_month, 'YYYY-MM-DD')
          }
        } else {
          return formatDate(end_of_month, 'YYYY-MM-DD')
        }
      } else {
        if (prev_date >= now) {
          return formatDate(prev_date, 'YYYY-MM-DD')
        } else {
          return formatDate(now, 'YYYY-MM-DD')
        }
      }
    },
  },
  created() {
    this.watch_modal_answer()
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  },
}
</script>

<style scoped lang="scss">
  .toolbar-wrap {
    :deep(.v-toolbar__content) {
      flex-wrap: wrap !important;
    }
    :deep(.v-toolbar__title) {
      flex: 1 1 100% !important;
    }
  }
</style>
